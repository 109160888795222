<template>
    <div>
        <b-row>
            <b-col cols="12" md="8">
                <b-table 
                    responsive outlined
                    :fields="fields" 
                    :items="items">
                    <template #cell(referencia)="data">
                        <div class="item-name">
                            <b>{{data.item.referencia}}</b>
                        </div>
                    </template>
                    
                    <template  v-slot:cell(nombre_item)="data">
                        <b-row class="item-detail">
                            <b-col cols="12">
                                <div>
                                    <b class="item-name">{{capitalizeFirstLetterOfAllWordsExceptFirst(data.item.nombre_item)}}</b>
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                    <template  v-slot:cell(cantidad)="data">
                        <b><span class="text-nowrap price">{{data.item.cantidad }}</span></b>
                    </template>
                    <template v-slot:cell(valor_real_unit)="data">
                        <b><span class="text-nowrap price">$ {{numberFormat(data.item.valor_real_unit) }}</span></b>
                    </template>
                    <template v-slot:cell(porcentaje_impuesto)="data">
                        <b class="price">{{data.item.porcentaje_impuesto}} % </b>
                    </template>
                    <template v-slot:cell(subtotal_items)="data">
                    <b class="text-nowrap price"> $ {{ numberFormat(data.item.subtotal_items) }}</b>
                    </template>
                </b-table>
            </b-col>
            <b-col cols="12" md="4">
                <b-row class="mx-2">
                    <b-col cols="12" class="border-section p-3 text-center">
                        <div class="pb-4">
                            <b>Comentario de la Orden:</b>
                        </div>
                        <div class="pt-1">
                            <div>{{datos.comentario}}</div>
                        </div>
                    </b-col>
                    <b-col cols="12" class="border-section mt-3 py-3">
                        <div class="text-center">
                            <div>
                                <div class="total-title"> Monto Total:</div>
                                <div class="total-title pt-2">$ {{numberFormat(datos.total_precio)}} COP</div>
                            </div>
                            <div class="pt-4" >
                                <div>Subtotal Bruto: <b>$ {{numberFormat(datos.subtotal_bruto)}}</b></div>
                            </div>
                            <div v-if="datos.descuento_comercial > 0">
                                <div>Descuento: <b>$ {{numberFormat(datos.descuento_comercial)}}</b></div>
                            </div>
                            <div>
                                <div>Subtotal Neto: <b>$ {{numberFormat(datos.subtotal_neto)}}</b></div>
                            </div>
                            <div>
                                <div>IVA: <b>$ {{numberFormat(datos.impuesto)}}</b></div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>
    
<script>
import {  mapState } from 'vuex'
export default {
    name: "ItemsComponent",
    props: {
        items: {
            type: Array
        },
        opcionExistencia: {
            type: Number
        },
        datos: {
            type: Object
        }
    },
    data(){
        return{
            fields: [
                { key: 'referencia', label: 'Referencia', sortable: false, tdClass: 'align-middle text-center', thClass: 'text-center'},
                
                { key: 'nombre_item', label: 'Item', sortable: true, thClass: 'text-center', tdClass: 'align-middle'},
                { key: 'cantidad', label: 'Cantidad', sortable: true, tdClass: 'align-middle text-center'},
                { key: 'valor_real_unit', label: 'Vr. Unit.', sortable: true, tdClass: 'align-middle text-center'},
                { key: 'porcentaje_impuesto', label: 'Imp. %', sortable: true, thClass: 'text-nowrap', tdClass: 'align-middle text-center'},
                { key: 'subtotal_items', label: 'Subtotal', sortable: true, tdClass: 'align-middle text-center'},
            ],
            modalDeleteItemFromCart: false,
            deleteItemDetail: {}
        }
    },
    computed:{
        ...mapState('auth', ['user']),
    }
};
    
</script>
<style scoped>

.border-section {
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.item-detail {
    min-width: 400px;
}

.item-quantity {
    min-width: 200px;
    max-width: 220px;
}

.item-name{
    font-size: 16px;
    color: #273c99 !important;
}

.price{
    color: rgb(5, 161, 5) !important;
    font-size: 18px;
}

.total-title{
    color: #313c49;
    font-size: 22px;
    font-weight: bold;
}

@media screen {
  .b-table {
    overflow-x: auto;
  }
}

@media print {
  .b-table {
    overflow-x: visible;
  }
}
</style>