<template>
    <div>
        <template>
            <b-card header-tag="header" footer-tag="footer" class="mt-8">
            <b-card-text>
                <p class="mb-0 text-center" style="font-size: 21px">Detalle del pedido #{{datos.id}}</p>
            </b-card-text>
            <b-card-text>
                <div><b>Fecha:</b> {{datos.created_at}}</div>
                <div><b>documento:</b> {{datos.documento_cliente}}</div>
                <div><b>correo:</b> {{datos.correo_cliente}}</div>
                <div><b>Telefono:</b> {{datos.telefono_cliente}}</div>
                <div><b>Dirección:</b> {{datos.direccion_cliente}}</div>
                <div><b>Departamento:</b> {{datos.departamento_cliente}}</div>
                <div><b>Ciudad:</b> {{datos.ciudad_cliente}}</div>
            </b-card-text>
            <b-card-text>
                <itemsComponent :items="productos" :datos="datos"/>
            </b-card-text>
            <template v-slot:footer>
                
                <b-button id="volver" variant="danger" 
                class="text-white mr-2" 
                :to="{name: 'ListadoPedidosClienteWeb'}">
                    Volver Listado
                </b-button>
            
                <b-button variant="info" 
                class="text-white mr-2" 
                @click.prevent="printWindow()">
                    Imprimir
                </b-button>
            
                <a variant="success" 
                class="text-white btn btn-success" 
                :href="urlWebBackend+'download-pedido-cliente-excel/'+datos.id" target="_blank">
                    Exportar csv
                </a>
                
            </template>
        </b-card>
        </template>
    </div>
</template>
<script>
import itemsComponent from '../components/ItemsComponent.vue'
import { mapState, mapMutations} from 'vuex'
export default {
    computed: {
        ...mapState('configuration', ['urlWebBackend']),
    },
    data(){
        return{
            datos: {},
            productos: [],
            id: this.$route.params.id,
        }
    },
    components: {
        itemsComponent
    },
    created(){
        this.getPedidoinfo();
    },
    methods:{
        ...mapMutations(['setLoading']),
        async getPedidoinfo(){
            this.setLoading(true)
            this.userPermissions = localStorage.getItem('user_permissions');
            await this.$conectar.post('pedidos-cliente-web/get-pedido-info', {id: this.id})
            .then(response =>{
                this.datos = response.data.pedidoLocal;
                this.productos = response.data.pedidoLocal.items;
            })
            .catch(e => {
                console.log(e.response);
            })
            this.setLoading(false)
            
        },
        printWindow() {
            window.print();
        }
    }
}
</script>
<style scoped>
.widthPersonalized{
    max-width: 400px;
}
.precio{
    min-width: 600px;
}
.singleLine th {
  white-space: nowrap !important;
  overflow: hidden !important;
}

.singleLines {
  white-space: nowrap !important;
  overflow: hidden !important;
}
@media print {
  button {
    display: none;
  }
  #volver{
    display: none;
  }
}


</style>